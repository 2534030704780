import { Module } from 'vuex';
import { $http, RootState } from '../index';
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface Result {
  bool: boolean;
  isSuccess: null | boolean;
  title: string;
  desc: string;
}
interface ExceptionItem {
  bool: boolean;
  type: string;
  title: string;
  desc: string;
}
interface Exception {
  paymentList: ExceptionItem;
}
interface PaymentListItem {
  bookingSid: number;
  paymentDate: string;
  patientName: string;
  nationality: string;
  doctorName: string;
  subject: string;
  price: string;
  state: string;
}
export interface AcctState {
  result: Result;
  exception: Exception;
  paymentList: null | Array<PaymentListItem>;
  totalCount: null | number;
}
export const moduleAcct: Module<AcctState, RootState> = {
  namespaced: true,
  state: {
    result: {
      bool: false,
      isSuccess: null,
      title: '',
      desc: '',
    },
    exception: {
      paymentList: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
    },
    paymentList: null,
    totalCount: null,
  },
  getters: {
    getLastPageNumber(state) {
      if (state.totalCount !== null) {
        if (state.totalCount % 8 === 0) {
          return Math.floor(state.totalCount / 8);
        } else {
          return Math.floor(state.totalCount / 8) + 1;
        }
      }
    },
  },
  mutations: {
    async setPaymentList(state, result) {
      console.log(result);
      state.paymentList = await [];
      if (result.cri !== null) {
        state.totalCount = result.cri.total_count;
      }
      for (let i = 0; i < result.payment_list.length; i++) {
        let priceVal;
        let stateVal;
        if (result.payment_list[i].payment_state === '00') {
          stateVal = '결제 완료';
        } else if (result.payment_list[i].payment_state === '01') {
          stateVal = '전체 취소';
        } else if (result.payment_list[i].payment_state === '02') {
          stateVal = '부분 취소';
        } else {
          stateVal = '-';
        }
        (() => {
          const val = result.payment_list[i].price;
          const vals: string[] = [];
          if (val.length === undefined) {
            priceVal = '-';
          } else {
            if (val.length > 3) {
              let nn = val;
              for (let i = 0; i < Math.floor(val.length / 3) + 1; i++) {
                if (nn.length % 3 !== 0) {
                  vals.push(`${nn.substr(0, nn.length % 3)}`);
                  nn = nn.substr(nn.length % 3, nn.length - (nn.length % 3));
                } else {
                  vals.push(`${nn.substr(0, 3)}`);
                  nn = nn.substr(3, nn.length - 3);
                }
              }
              console.log(vals);
              priceVal = vals.join();
            } else {
              priceVal = val;
            }
          }
        })();
        state.paymentList.push({
          bookingSid: result.payment_list[i].booking_sid,
          paymentDate: result.payment_list[i].payment_date,
          patientName: result.payment_list[i].patient_name,
          nationality: result.payment_list[i].patient_nationality,
          doctorName: result.payment_list[i].physician_name,
          subject: result.payment_list[i].medical_subject_name,
          price: priceVal,
          state: stateVal,
        });
      }
      if (result.payment_list.length < 1) {
        return setException(state.exception.paymentList, true, `empty`, `결제 목록 조회`, `조회 가능한 결제 목록이 없습니다.`);
      } else {
        return setException(state.exception.paymentList, false, `success`, `결제 목록 조회`, `${result.cri.totalCount}개 조회 성공.`);
      }
    },
    async setPaymentListError(state, err) {
      return setException(state.exception.paymentList, true, `bang`, `결제 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
  },
  actions: {
    async getPaymentList(context, param) {
      // console.log(param);
      const config = await {
        params: {
          page_index: param.page_index,
          page_rows: param.page_rows,
          search_period: param.search_period,
          search_start_date: param.search_start_date,
          search_end_date: param.search_end_date,
          search_text: param.search_text,
          search_type: param.search_type,
          payment_state: param.payment_state,
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/account/payment/list', config)
        .then((res) => {
          // console.log(res);
          context.commit('setPaymentList', res.data);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setPaymentListError', err.response.data);
        });
    },
  },
};
