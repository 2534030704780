import { Module } from 'vuex';
import { $http, RootState } from '../index';
const setResult = (st: any, bl: boolean, sc: boolean, tl: string, ds: string): void => {
  st.result.isSuccess = sc;
  st.result.title = tl;
  st.result.desc = ds;
  setTimeout(() => {
    st.result.bool = bl;
  }, 300);
};
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface PeriodStr {
  today: string;
  oneWeek: string;
  threeWeeks: string;
  oneMonth: string;
  threeMonths: string;
}
interface ExceptionItem {
  bool: boolean;
  type: string;
  title: string;
  desc: string;
}
interface Exception {
  appoint_waiting: null | ExceptionItem;
  appoint_confirm: null | ExceptionItem;
  appoint_finished: null | ExceptionItem;
  visitor_count: null | ExceptionItem;
  doctorApplyList: null | ExceptionItem;
  faqList: null | ExceptionItem;
  noticeList: null | ExceptionItem;
  appoint_graph: ExceptionItem;
}
interface boardListItem {
  title: string;
  date: string;
}
interface doctorApplyListItem {
  nationality: string;
  doctorName: string;
  profileImageUrl: null | string;
  specialSubjects: null | Array<string>;
  statusName: string;
}
interface countItem {
  today: string;
  add: string;
}
export interface MainState {
  periodStr: PeriodStr;
  isReady: boolean;
  exception: Exception;
  appoint_waiting: countItem;
  appoint_confirm: countItem;
  appoint_finished: countItem;
  visitor_count: countItem;
  doctorApplyList: null | Array<doctorApplyListItem>;
  doctorApplyList_totalCount: number;
  faqList: null | Array<boardListItem>;
  faqList_totalCount: number;
  noticeList: null | Array<boardListItem>;
  noticeList_totalCount: number;
  appoint_graph: null | any;
  xVals: null | Array<string>;
  yVals: null | Array<number>;
}
export const moduleMain: Module<MainState, RootState> = {
  namespaced: true,
  state: {
    periodStr: {
      today: '',
      oneWeek: '',
      threeWeeks: '',
      oneMonth: '',
      threeMonths: '',
    },
    isReady: false,
    exception: {
      appoint_waiting: null,
      appoint_confirm: null,
      appoint_finished: null,
      visitor_count: null,
      doctorApplyList: null,
      faqList: null,
      noticeList: null,
      appoint_graph: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
    },
    appoint_waiting: {
      today: ``,
      add: ``,
    },
    appoint_confirm: {
      today: ``,
      add: ``,
    },
    appoint_finished: {
      today: ``,
      add: ``,
    },
    visitor_count: {
      today: ``,
      add: ``,
    },
    doctorApplyList: null,
    doctorApplyList_totalCount: 0,
    faqList: null,
    faqList_totalCount: 0,
    noticeList: null,
    noticeList_totalCount: 0,
    appoint_graph: null,
    xVals: null,
    yVals: null,
  },
  getters: {
    getTodayStr(state) {
      const today = new Date();
      const year = today.getFullYear();
      let month: number | string;
      month = today.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let date: number | string;
      date = today.getDate();
      if (date < 10) {
        date = `0${date}`;
      }
      state.periodStr.today = `${year}-${month}-${date}`;
      return state.periodStr.today;
    },
    getOneWeekStr(state) {
      const today = new Date();
      let year = today.getFullYear();
      let month: number | string;
      month = today.getMonth() + 1;
      let date: number | string;
      date = today.getDate() - 7;
      if (date < 1) {
        month -= 1;
        date += 30;
      }
      if (month < 1) {
        year -= 1;
        month += 12;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }
      state.periodStr.oneWeek = `${year}-${month}-${date}`;
      return state.periodStr.oneWeek;
    },
    getOneMonthStr(state) {
      const today = new Date();
      let year = today.getFullYear();
      let month: number | string;
      month = today.getMonth();
      let date: number | string;
      date = today.getDate();
      if (month < 1) {
        year -= 1;
        month += 12;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }
      state.periodStr.oneMonth = `${year}-${month}-${date}`;
      return state.periodStr.oneMonth;
    },
    getThreeMonthsStr(state) {
      const today = new Date();
      let year = today.getFullYear();
      let month: number | string;
      month = today.getMonth() - 2;
      let date: number | string;
      date = today.getDate();
      if (month < 1) {
        year -= 1;
        month += 12;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }
      state.periodStr.threeMonths = `${year}-${month}-${date}`;
      return state.periodStr.threeMonths;
    },
  },
  mutations: {
    async setAppointGraph(state, result) {
      const setData = () => {
        state.xVals = [];
        state.yVals = [];
        for (let i = 0; i < state.appoint_graph.length; i++) {
          state.xVals.push(state.appoint_graph[i].schedule_date);
          state.yVals.push(state.appoint_graph[i].cnt);
        }
        if (state.appoint_graph.length === state.xVals.length) {
          setTimeout(() => {
            state.isReady = true;
          }, 400);
        }
      };
      if (result.length === 0) {
        state.exception.appoint_graph.bool = true;
        state.exception.appoint_graph.type = 'bang';
        state.exception.appoint_graph.desc = '조회 가능한 데이터가 없습니다.';
        state.isReady = true;
      } else {
        const limitList = [];
        if (result.length > 31) {
          let divNum = Math.floor(result.length / 31) + 1;
          const addNum = divNum;
          for (let i = 0; i < result.length && divNum < result.length - 1 - addNum; i++) {
            divNum += addNum;
            limitList.push(result[divNum]);
          }
          state.appoint_graph = await limitList;
          await setData();
        } else {
          state.appoint_graph = result;
          setData();
        }
      }
    },
    async setCount(state, result) {
      // console.log(result);
      state.appoint_waiting = {
        today: result.today_wait.toString(),
        add: (result.today_wait - result.yesterday_wait).toString(),
      };
      state.appoint_confirm = {
        today: result.today_confirm.toString(),
        add: (result.today_confirm - result.yesterday_confirm).toString(),
      };
      state.appoint_finished = {
        today: result.today_finish.toString(),
        add: (result.today_finish - result.yesterday_finish).toString(),
      };
      state.visitor_count = {
        today: result.today_user.toString(),
        add: (result.today_user - result.yesterday_user).toString(),
      };
    },
    async setCountError(state, err) {
      console.log(err);
    },
    async setDoctorApply_list(state, result) {
      state.doctorApplyList = await [];
      state.doctorApplyList_totalCount = result.totalCount;
      for (let i = 0; i < result.physician.length; i++) {
        let statusVal = '대기';
        let profileImageUrlVal = null;
        // profile image
        await (async () => {
          const type = `01`; // 이미지 유형(00:의사면허, 01:프로필 이미지)
          const queryStr = `/api/admin/physician/manager/image/${result.physician[i].physician_sid}/${type}`;
          await $http
            .get(process.env.VUE_APP_API_HOST + queryStr)
            .then((res) => {
              if (res.data.image_datas.length !== 0) {
                profileImageUrlVal = res.data.image_datas[0].image_url;
              } else {
                profileImageUrlVal = `unknown`;
              }
            })
            .catch((err) => {
              profileImageUrlVal = null;
            });
        })();
        if (result.physician[i].apply_yn === 'Y') {
          statusVal = '승인';
        } else if (result.physician[i].apply_yn === 'N') {
          statusVal = '대기';
        }
        let subjectsVal = null;
        if (
          result.physician[i].speciality_list !== null &&
          result.physician[i].speciality_list !== 'null' &&
          result.physician[i].speciality_list !== undefined &&
          result.physician[i].speciality_list.length !== 0
        ) {
          subjectsVal = [];
          for (let j = 0; j < result.physician[i].speciality_list.length; j++) {
            subjectsVal.push(result.physician[i].speciality_list[j].subject.name);
          }
        }
        state.doctorApplyList.push({
          nationality: result.physician[i].nationality,
          doctorName: result.physician[i].physician_name,
          profileImageUrl: profileImageUrlVal,
          specialSubjects: subjectsVal,
          statusName: statusVal,
        });
      }
      if (result.physician.length < 1) {
        return setException(state.exception.doctorApplyList, true, `empty`, `의료진 목록 조회`, `조회 가능한 의료진 목록이 없습니다.`);
      } else {
        return setException(state.exception.doctorApplyList, false, `success`, `의료진 목록 조회`, `${result.totalCount}개 조회 성공.`);
      }
    },
    async setDoctorApply_listError(state, err) {
      return setException(state.exception.doctorApplyList, true, `bang`, `의료진 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setBoard_list(state, result) {
      if (result.list_board[0].board_category_code === `102`) {
        state.noticeList_totalCount = result.total_count;
        state.noticeList = await [];
        for (let i = 0; i < result.list_board.length; i++) {
          state.noticeList.push({
            title: result.list_board[i].title,
            date: result.list_board[i].create_date.substr(0, 10),
          });
        }
      } else {
        state.faqList_totalCount = result.total_count;
        state.faqList = await [];
        for (let i = 0; i < result.list_board.length; i++) {
          state.faqList.push({
            title: result.list_board[i].title,
            date: result.list_board[i].create_date.substr(0, 10),
          });
        }
      }
      if (result.physician.length < 1) {
        return setException(state.exception.doctorApplyList, true, `empty`, `의료진 목록 조회`, `조회 가능한 의료진 목록이 없습니다.`);
      } else {
        return setException(state.exception.doctorApplyList, false, `success`, `의료진 목록 조회`, `${result.totalCount}개 조회 성공.`);
      }
    },
    async setBoard_listError(state, err) {
      return setException(state.exception.doctorApplyList, true, `bang`, `의료진 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
  },
  actions: {
    async getAppointGraph(context, param) {
      const queryStr = await `/api/admin/main/consult/${param.begin_date}/${param.end_date}`;
      // console.log(process.env.VUE_APP_API_HOST + queryStr);
      await $http
        .get(process.env.VUE_APP_API_HOST + queryStr)
        .then((res) => {
          // console.log(res);
          context.commit('setAppointGraph', res.data.list_count);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setAppointGraphError', err);
        });
    },
    async getCount(context, param) {
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/main/stats')
        .then((res) => {
          context.commit('setCount', res.data);
        })
        .catch((err) => {
          context.commit('setCountError', err);
        });
    },
    async getDoctorApply_list(context, param) {
      const config = await {
        params: {
          page: param.page,
          rows: param.rows,
          search_date_begin: param.search_date_begin,
          search_date_end: param.search_date_end,
          search_text: param.search_text,
          search_type: param.search_type,
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/list', config)
        .then((res) => {
          context.commit('setDoctorApply_list', res.data);
        })
        .catch((err) => {
          context.commit('setDoctorApply_listError', err);
        });
    },
    async getBoard_list(context, board) {
      // console.log(board);
      const params = await {
        board_category_code: board.boardCategory,
        board_type_code: board.boardType,
        page: board.pageNum,
        rows: board.rowNum,
        search_text: board.searchWord,
      };
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/board/list_board.seize', params)
        .then((res) => {
          context.commit('setBoard_list', res.data);
        })
        .catch((err) => {
          context.commit('setBoard_listError', err);
        });
    },
  },
};
