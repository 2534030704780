<template>
  <div id="main">
    <h1 class="hidden">메인</h1>
  </div>
  <div class="wait"></div>
</template>

<script setup lang="ts"></script>
<style scoped lang="scss">
@import '@/assets/style/variable';
@import '@/assets/style/mixin';
@import '@/assets/style/content';
</style>
