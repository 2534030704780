import { Module } from 'vuex';
import { RootState } from '../index';
import axios from 'axios';
import router from '@/router';
interface UserInfo {
  authId: string;
  authName: string;
  authPw: string;
  authSid: number;
}
export interface AuthState {
  userInfo: null | UserInfo;
  isLogin: boolean;
  isLoginError: boolean;
}
export const moduleAuth: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    userInfo: {
      authId: '',
      authName: '',
      authPw: '',
      authSid: 0,
    },
    isLogin: false,
    isLoginError: false,
  },
  mutations: {
    loginSuccess(state, payload) {
      state.isLogin = true;
      state.isLoginError = false;
      state.userInfo = {
        authId: payload.member.admin_member_id,
        authName: payload.member.admin_member_name,
        authPw: payload.member.admin_member_pw,
        authSid: payload.member.admin_member_sid,
      };
      localStorage.setItem('authToken', payload.session_key);
      localStorage.setItem('isValidToken', 'true');
      location.href = location.protocol + '//' + location.host;
    },
    loginError(state, err) {
      console.log(err);
      localStorage.setItem('isValidToken', 'false');
      state.isLogin = false;
      state.isLoginError = true;
    },
    logout(state) {
      state.userInfo = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('isValidToken');
    },
  },
  actions: {
    async login(context, userInfo) {
      const params = {
        admin_member_id: userInfo.userId,
        admin_member_pw: userInfo.userPw,
      };
      await axios
        .post(process.env.VUE_APP_API_HOST + '/api/admin/main/login.seize', params)
        .then((res) => {
          context.commit('loginSuccess', res.data);
        })
        .catch((err) => {
          context.commit('loginError', err);
        });
    },
    logout(context) {
      context.commit('logout');
    },
  },
};
