import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { setupCalendar } from 'v-calendar';
import 'v-calendar/style.css';

declare global {
  interface Window {
    temProperty: string;
    vw: unknown;
  }
}
const app = createApp(App).use(store).use(router).use(setupCalendar, {}).mount('#app');
window.vw = app;
