import { Module } from 'vuex';
import { $http, RootState } from '../index';
const setResult = (st: any, bl: boolean, sc: boolean, tl: string, ds: string): void => {
  st.result.isSuccess = sc;
  st.result.title = tl;
  st.result.desc = ds;
  setTimeout(() => {
    st.result.bool = bl;
  }, 300);
};
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface Result {
  bool: boolean;
  isSuccess: null | boolean;
  title: string;
  desc: string;
}
interface ExceptionItem {
  bool: boolean;
  type: string;
  title: string;
  desc: string;
}
interface Exception {
  subjects: ExceptionItem;
  diseases_onSubject: ExceptionItem;
  diseases_all: ExceptionItem;
}
interface Item {
  code: string;
  nameKr: string;
  nameEn: string;
  nameRu: string;
  isChecked?: boolean;
}
export interface SetState {
  result: Result;
  exception: Exception;
  subjects: null | Array<Item>;
  diseases_onSubject: null | Array<Item>;
  diseases_all: null | Array<Item>;
  newSubject_code: null | string;
  newDiseases_onSubject_code: null | Array<string>;
  newDisease_all_code: null | string;
}
export const moduleSet: Module<SetState, RootState> = {
  namespaced: true,
  state: {
    result: {
      bool: false,
      isSuccess: null,
      title: '',
      desc: '',
    },
    exception: {
      subjects: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
      diseases_onSubject: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
      diseases_all: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
    },
    subjects: null,
    diseases_onSubject: null,
    diseases_all: null,
    newSubject_code: null,
    newDiseases_onSubject_code: null,
    newDisease_all_code: null,
  },
  getters: {
    getCheckedSubjects(state) {
      return state.subjects?.filter((subject) => subject.isChecked === true);
    },
    getCheckedDiseases_onSubject(state) {
      return state.diseases_onSubject?.filter((disease_onSubject) => disease_onSubject.isChecked === true);
    },
    getCheckedDiseases_all(state) {
      return state.diseases_all?.filter((disease_all) => disease_all.isChecked === true);
    },
  },
  mutations: {
    async addNew_disease_all(state, item) {
      state.newDisease_all_code = item.disease_code;
      return setResult(state, true, true, `새 진료질환 추가`, `'${item.disease_name_ko}' 추가 성공`);
    },
    async addNew_disease_allError(state, err) {
      return setResult(state, true, false, `새 진료질환 추가`, `등록 실패. ERROR ${err.code}: ${err.message}`);
    },
    async delete_diseases_all(state, length) {
      return setResult(state, true, true, `진료질환 삭제`, `${length}개 삭제 성공`);
    },
    async delete_diseases_allError(state, err) {
      return setResult(state, true, false, `진료질환 삭제`, `삭제 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setDiseases_onSubject(state, list) {
      // console.log(list);
      state.diseases_onSubject = await [];
      for (let i = 0; i < list.length; i++) {
        state.diseases_onSubject.push({
          code: list[i].disease_code,
          nameEn: list[i].disease_name_en,
          nameKr: list[i].disease_name_ko,
          nameRu: list[i].disease_name_ru,
          isChecked: false,
        });
      }
      if (list.length < 1) {
        return setException(state.exception.diseases_onSubject, true, `empty`, `하위 진료질환 조회`, `하위 진료질환을 추가해 주세요.`);
      } else {
        return setException(state.exception.diseases_onSubject, false, `success`, `하위 진료질환 조회`, `${list.length}개 조회 성공.`);
      }
    },
    async setDiseases_onSubjectError(state, err) {
      return setException(state.exception.diseases_onSubject, true, `bang`, `하위 진료질환 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async resetDiseases_onSubject(state) {
      state.diseases_onSubject = [];
      return setException(state.exception.diseases_onSubject, false, ``, ``, ``);
    },
    async setDiseases_all(state, list) {
      // console.log(list);
      state.diseases_all = await [];
      for (let i = 0; i < list.length; i++) {
        state.diseases_all.push({
          code: list[i].disease_code,
          nameEn: list[i].disease_name_en,
          nameKr: list[i].disease_name_ko,
          nameRu: list[i].disease_name_ru,
          isChecked: false,
        });
      }
      if (list.length < 1) {
        return setException(state.exception.diseases_all, true, `empty`, `전체 진료질환 조회`, `하위 진료질환을 추가해 주세요.`);
      } else {
        return setException(state.exception.diseases_all, false, `success`, `전체 진료질환 조회`, `${list.length}개 조회 성공.`);
      }
    },
    async setDiseases_allError(state, err) {
      return setException(state.exception.diseases_all, true, `bang`, `전체 진료질환 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async resetDiseases_all(state) {
      state.diseases_all = await [];
      return setException(state.exception.diseases_all, false, ``, ``, ``);
    },
    async modify_disease_all(state, item) {
      return setResult(state, true, true, `진료질환 수정`, `'${item.disease_name_ko}' 수정 성공`);
    },
    async modify_disease_allError(state, err) {
      return setResult(state, true, false, `진료질환 수정`, `추가 실패. ERROR ${err.code}: ${err.message}`);
    },
    async connect_diseases_onSubject(state, codeData) {
      // addNew style
      state.newDiseases_onSubject_code = codeData.diseasesCode;
      return setResult(state, true, true, `하위 진료질환 추가`, `${codeData.diseasesCode.length}개 추가 성공`);
    },
    async connect_diseases_onSubjectError(state, err) {
      return setResult(state, true, false, `하위 진료질환 추가`, `추가 실패. ERROR ${err.code}: ${err.message}`);
    },
    async disconnect_diseases_onSubject(state, codeData) {
      return setResult(state, true, true, `하위 진료질환 삭제`, `${codeData.diseasesCode.length}개 삭제 성공`);
    },
    async disconnect_diseases_onSubjectError(state, err) {
      return setResult(state, true, false, `하위 진료질환 삭제`, `삭제 실패. ERROR ${err.code}: ${err.message}`);
    },
    async addNew_subject(state, item) {
      state.newSubject_code = item.medical_subject_code;
      return setResult(state, true, true, `새 진료과목 추가`, `'${item.subject_name_ko}' 추가 성공`);
    },
    async addNew_subjectError(state, err) {
      return setResult(state, true, false, `새 진료과목 추가`, `추가 실패. ERROR ${err.code}: ${err.message}`);
    },
    async delete_subjects(state, length) {
      return setResult(state, true, true, `진료과목 삭제`, `${length}개 삭제 성공`);
    },
    async delete_subjectsError(state, err) {
      return setResult(state, true, false, `진료과목 삭제`, `삭제 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setSubjects(state, list) {
      // console.log(code_subject);
      state.subjects = await [];
      for (let i = 0; i < list.length; i++) {
        state.subjects.push({
          code: list[i].medical_subject_code,
          nameEn: list[i].subject_name_en,
          nameKr: list[i].subject_name_ko,
          nameRu: list[i].subject_name_ru,
          isChecked: false,
        });
      }
      state.exception.subjects.title = `진료과목 조회`;
      if (list.length < 1) {
        return setException(state.exception.subjects, true, `empty`, `진료과목  조회`, `새 진료과목을 추가해 주세요.`);
      } else {
        return setException(state.exception.subjects, false, `success`, `진료과목 조회`, `${list.length}개 조회 성공.`);
      }
    },
    async setSubjectsError(state, err) {
      state.subjects = await [];
      setException(state.exception.subjects, true, `bang`, `진료과목 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async resetSubjects(state) {
      state.diseases_onSubject = await [];
      setException(state.exception.subjects, false, ``, ``, ``);
    },
    async modify_subject(state, item) {
      // console.log(item);
      return setResult(state, true, true, `진료과목 수정`, `'${item.subject_name_ko}' 수정 성공`);
    },
    async modify_subjectError(state, err) {
      return setResult(state, true, false, `진료과목 수정`, `수정 실패. ERROR ${err.code}: ${err.message}`);
    },
    async check_subject(state, index) {
      if (state.subjects !== null) {
        state.subjects[index].isChecked = true;
      }
    },
    async uncheck_subject(state, index) {
      if (state.subjects !== null) {
        state.subjects[index].isChecked = false;
      }
    },
    async uncheck_subjects(state) {
      if (state.subjects !== null) {
        for (let i = 0; i < state.subjects.length; i++) {
          state.subjects[i].isChecked = false;
        }
      }
    },
    async check_disease_onSubject(state, index) {
      if (state.diseases_onSubject !== null) {
        state.diseases_onSubject[index].isChecked = true;
      }
    },
    async uncheck_disease_onSubject(state, index) {
      if (state.diseases_onSubject !== null) {
        state.diseases_onSubject[index].isChecked = false;
      }
    },
    async uncheck_diseases_onSubject(state) {
      if (state.diseases_onSubject !== null) {
        for (let i = 0; i < state.diseases_onSubject.length; i++) {
          state.diseases_onSubject[i].isChecked = false;
        }
      }
    },
    async check_disease_all(state, index) {
      if (state.diseases_all !== null) {
        state.diseases_all[index].isChecked = true;
      }
    },
    async uncheck_disease_all(state, index) {
      if (state.diseases_all !== null) {
        state.diseases_all[index].isChecked = false;
      }
    },
    async uncheck_diseases_all(state) {
      if (state.diseases_all !== null) {
        for (let i = 0; i < state.diseases_all.length; i++) {
          state.diseases_all[i].isChecked = false;
        }
      }
    },
    async reset_result(state) {
      (state.result.bool = false), (state.result.isSuccess = null), (state.result.desc = '');
    },
  },
  actions: {
    async addNew_disease_all(context, item) {
      const objParam = {
        disease_code: item.code,
        disease_name_en: item.nameEn,
        disease_name_ko: item.nameKr,
        disease_name_ru: item.nameRu,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/disease_add', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('addNew_disease_all', res.data.code_disease);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('addNew_disease_allError', err);
        });
    },
    async delete_diseases_all(context, array) {
      // console.log(array);
      const objParam = {
        disease_code: array,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/disease_delete', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('delete_diseases_all', array.length);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('delete_diseases_allError', err);
        });
    },
    async getDiseases(context, subjectCode) {
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/disease_list', {
          params: {
            subject_code: subjectCode, // ('': 전체)
          },
        })
        .then((res) => {
          // console.log(res);
          if (subjectCode === '') {
            context.commit('setDiseases_all', res.data.code_disease);
          } else {
            context.commit('setDiseases_onSubject', res.data.code_disease);
          }
        })
        .catch((err) => {
          // console.log(err);
          if (subjectCode === '') {
            // ('': 전체)
            context.commit('setDiseases_allError', err);
          } else {
            context.commit('setDiseases_onSubjectError', err);
          }
        });
    },
    async modify_disease_all(context, item) {
      const objParam = {
        disease_code: item.code,
        disease_name_en: item.nameEn,
        disease_name_ko: item.nameKr,
        disease_name_ru: item.nameRu,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/disease_modify', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('modify_disease_all', res.data.code_disease);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('modify_disease_allError', err);
        });
    },
    async connect_diseases_onSubject(context, codeData) {
      const objParam = {
        medical_subject_code: codeData.subjectCode,
        disease_code: codeData.diseasesCode,
      };
      console.log(objParam);
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/match_add', queryParam)
        .then((res) => {
          console.log(res);
          context.commit('connect_diseases_onSubject', codeData);
        })
        .catch((err) => {
          console.log(err);
          context.commit('connect_diseases_onSubjectError', err);
        });
    },
    async disconnect_diseases_onSubject(context, codeData) {
      // console.log(codeData);
      const objParam = {
        medical_subject_code: codeData.subjectCode,
        disease_code: codeData.diseasesCode,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/match_del', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('disconnect_diseases_onSubject', codeData);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('disconnect_diseases_onSubjectError', err);
        });
    },
    async addNew_subject(context, item) {
      const objParam = {
        medical_subject_code: item.code,
        subject_name_en: item.nameEn,
        subject_name_ko: item.nameKr,
        subject_name_ru: item.nameRu,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/subject_add', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('addNew_subject', res.data.code_subject);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('addNew_subjectError', err);
        });
    },
    async delete_subjects(context, array) {
      const objParam = {
        medical_subject_code: array,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/subject_delete', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('delete_subjects', array.length);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('delete_subjectsError', err);
        });
    },
    async getSubjects(context) {
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/subject_list')
        .then((res) => {
          // console.log(res);
          context.commit('setSubjects', res.data.code_subject);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setSubjectsError', err);
        });
    },
    async modify_subject(context, item) {
      const objParam = {
        medical_subject_code: item.code,
        subject_name_en: item.nameEn,
        subject_name_ko: item.nameKr,
        subject_name_ru: item.nameRu,
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/code/manager/subject_modify', queryParam)
        .then((res) => {
          // console.log(res);
          context.commit('modify_subject', res.data.code_subject);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('modify_subjectError', err);
        });
    },
  },
};
