import { Module } from 'vuex';
import { RootState } from '../index';
interface ItemState {
  name: string;
  info?: string;
  active: boolean;
  routerName: string;
}
interface DepthState {
  depth1: ItemState;
  depth2: Array<ItemState>;
}
export interface MenuState {
  bodyTitle: string;
  bodyDesc: undefined | string;
  gnbStyles: boolean[];
  menus: number[];
  menu: Array<DepthState>;
}
export const moduleMenu: Module<MenuState, RootState> = {
  namespaced: true,
  state: {
    bodyTitle: '',
    bodyDesc: '',
    gnbStyles: [false, false, false, false, false, false, false],
    menus: [0, 0],
    menu: [
      // [0]
      {
        depth1: {
          name: '메인',
          active: false,
          routerName: 'Main',
        },
        depth2: [
          {
            name: '대시보드',
            info: '대시보드 페이지입니다.',
            active: false,
            routerName: 'DashBoard',
          },
        ],
      },
      // [1]
      {
        depth1: {
          name: '의료진 관리',
          active: false,
          routerName: 'Doctor',
        },
        depth2: [
          {
            name: '의료진 등록',
            info: '의료진을 등록하면 이메일로 정보를 보내드립니다.',
            active: false,
            routerName: 'DoctorRegistration',
          },
          {
            name: '의료진 현황',
            info: '의료진 현황 목록입니다.',
            active: false,
            routerName: 'DoctorList',
          },
        ],
      },
      // [2]
      {
        depth1: {
          name: '환자 관리',
          active: false,
          routerName: 'Patient',
        },
        depth2: [
          {
            name: '환자 현황',
            info: '환자 현황 목록입니다.',
            active: false,
            routerName: 'PatientList',
          },
        ],
      },
      // [3]
      {
        depth1: {
          name: '상담 관리',
          active: false,
          routerName: 'Appoint',
        },
        depth2: [
          {
            name: '상담 현황',
            info: '상담 현황 목록입니다.',
            active: false,
            routerName: 'AppointList',
          },
        ],
      },
      // [4]
      {
        depth1: {
          name: '결제 관리',
          active: false,
          routerName: 'Cost',
        },
        depth2: [
          {
            name: '결제 현황',
            info: '환자별 결제 현황 목록입니다.',
            active: false,
            routerName: 'PaymentList',
          },
        ],
      },
      // [5]
      {
        depth1: {
          name: '고객센터',
          active: false,
          routerName: 'Cs',
        },
        depth2: [
          {
            name: '의료진 공지사항',
            info: '의료진 공지사항 언어별 페이지입니다.',
            active: false,
            routerName: 'DoctorNotice',
          },
          {
            name: '의료진 FAQ',
            info: '의료진 관련 자주 묻는 질문과 답변 언어별 페이지입니다.',
            active: false,
            routerName: 'DoctorFAQ',
          },
          {
            name: '환자 공지사항',
            info: '환자 공지사항 언어별 페이지입니다.',
            active: false,
            routerName: 'PatientNotice',
          },
          {
            name: '환자 FAQ',
            info: '환자 관련 자주 묻는 질문과 답변 언어별 페이지입니다.',
            active: false,
            routerName: 'PatientFAQ',
          },
        ],
      },
      // [6]
      {
        depth1: {
          name: '설정 관리',
          active: false,
          routerName: 'Setting',
        },
        depth2: [
          {
            name: '카테고리 설정',
            info: '진료질환 및 하위 진료질환 설정 페이지입니다.',
            active: false,
            routerName: 'Category',
          },
        ],
      },
    ],
  },
  getters: {
    getBodyTitle(state) {
      return state.menu[state.menus[0]].depth2[state.menus[1]].name;
    },
    getBodyDesc(state) {
      return state.menu[state.menus[0]].depth2[state.menus[1]].info;
    },
  },
  mutations: {
    async setMenus(state, paramName) {
      for (let i = 0; i < state.menu.length; i++) {
        for (let j = 0; j < state.menu[i].depth2.length; j++) {
          if (state.menu[i].depth2[j].routerName === paramName) {
            state.menus[0] = i;
            state.menus[1] = j;
          }
        }
      }
      state.bodyTitle = await state.menu[state.menus[0]].depth2[state.menus[1]].name;
      state.bodyDesc = await state.menu[state.menus[0]].depth2[state.menus[1]].info;
    },
  },
  actions: {
    setMenus(context, routerName) {
      context.commit('setMenus', routerName);
    },
  },
};
