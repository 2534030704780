import { Module } from 'vuex';
import { $http, RootState } from '../index';
const setResult = (st: any, bl: boolean, sc: boolean, tl: string, ds: string): void => {
  st.result.isSuccess = sc;
  st.result.title = tl;
  st.result.desc = ds;
  setTimeout(() => {
    st.result.bool = bl;
  }, 300);
};
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface Patient {
  name: string;
  patientEmail: string;
  managerEmail: string;
  nationality: string;
  state: string;
}
interface Doctor {
  name: string;
  doctorEmail: string;
  managerEmail: string;
  nationality: string;
  affiliation: string;
  state: string;
}
interface Detail {
  title: string;
  content: string;
  images: Array<string>;
}
interface Schedule {
  date: string;
  time: string;
  endTime: string;
  state: string;
  memo: string;
  reject: string;
}
interface AppointInfo {
  patient: Patient;
  doctor: Doctor;
  detail: Detail;
  schedule: Schedule;
}
interface ExceptionItem {
  bool: boolean;
  type: string;
  title: string;
  desc: string;
}
interface Exception {
  appointList: ExceptionItem;
  appointInfo: ExceptionItem;
}
interface AppointListItem {
  bookingSid: number;
  appointDate: string;
  appointTime: string;
  patientName: string;
  doctorName: string;
  patientState: string;
  doctorState: string;
  appointState: string;
}
export interface ApptState {
  exception: Exception;
  appointList: null | Array<AppointListItem>;
  totalCount: null | number;
  appointInfo: null | AppointInfo;
}
export const moduleAppt: Module<ApptState, RootState> = {
  namespaced: true,
  state: {
    exception: {
      appointList: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
      appointInfo: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
    },
    appointList: null,
    totalCount: null,
    appointInfo: {
      patient: {
        name: ``,
        patientEmail: ``,
        managerEmail: ``,
        nationality: ``,
        state: ``,
      },
      doctor: {
        name: ``,
        doctorEmail: ``,
        managerEmail: ``,
        nationality: ``,
        affiliation: ``,
        state: ``,
      },
      detail: {
        title: ``,
        content: ``,
        images: [``],
      },
      schedule: {
        date: ``,
        time: ``,
        endTime: ``,
        state: ``,
        memo: ``,
        reject: ``,
      },
    },
  },
  getters: {
    getLastPageNumber(state) {
      if (state.totalCount !== null) {
        if (state.totalCount % 8 === 0) {
          return Math.floor(state.totalCount / 8);
        } else {
          return Math.floor(state.totalCount / 8) + 1;
        }
      }
    },
  },
  mutations: {
    async setAppointList(state, result) {
      // console.log('------list total count');
      // onsole.log(result.cri.total_count);
      state.appointList = await [];
      state.totalCount = result.cri.total_count;
      for (let i = 0; i < result.consult_list.length; i++) {
        let patientStateVal = ``;
        let doctorStateVal = ``;
        let appointStateVal = ``;
        // 환자 요청 상태
        if (result.consult_list[i].patient_action === `00`) {
          patientStateVal = `요청`;
        } else if (result.consult_list[i].patient_action === `01`) {
          patientStateVal = `취소`;
        }
        // 의료진 응답 상태
        if (result.consult_list[i].physician_state === `00`) {
          doctorStateVal = `미응답`;
        } else if (result.consult_list[i].physician_state === `01`) {
          doctorStateVal = `확정`;
        } else if (result.consult_list[i].physician_state === `02`) {
          doctorStateVal = `반려`;
        }
        // 상담 진행 상태
        if (result.consult_list[i].schedule_state === `03`) {
          appointStateVal = `대기`;
        } else if (result.consult_list[i].schedule_state === `04`) {
          appointStateVal = `확정`;
        } else if (result.consult_list[i].schedule_state === `05`) {
          appointStateVal = `완료`;
        } else if (result.consult_list[i].schedule_state === `08`) {
          appointStateVal = `반려`;
        } else if (result.consult_list[i].schedule_state === `99`) {
          appointStateVal = `미응답`;
        } else if (result.consult_list[i].schedule_state === `999`) {
          appointStateVal = `노쇼`;
        } else {
          appointStateVal = '-';
        }
        state.appointList.push({
          bookingSid: result.consult_list[i].booking_sid,
          appointDate: result.consult_list[i].schedule_date.substr(0, 10),
          appointTime: result.consult_list[i].schedule_time,
          patientName: result.consult_list[i].patient_name,
          doctorName: result.consult_list[i].physician_name,
          patientState: patientStateVal,
          doctorState: doctorStateVal,
          appointState: appointStateVal,
        });
      }
      if (result.consult_list.length < 1) {
        return setException(state.exception.appointList, true, `empty`, `상담 목록 조회`, `조회 가능한 상담 목록이 없습니다.`);
      } else {
        return setException(state.exception.appointList, false, `success`, `상담 목록 조회`, `${result.cri.total_count}개 조회 성공.`);
      }
    },
    setAppointListError(state, err) {
      return setException(state.exception.appointList, false, `bang`, `상담 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setAppointInfo(state, result) {
      let patientStateVal, doctorStateVal, scheduleStateVal;
      if (result.booking_detail.patient_action === '00') {
        patientStateVal = '요청';
      } else if (result.booking_detail.patient_action === '01') {
        patientStateVal = '취소';
      } else {
        patientStateVal = '-';
      }
      if (result.booking_detail.physician_state === '00') {
        doctorStateVal = '미응답';
      } else if (result.booking_detail.physician_state === '01') {
        doctorStateVal = '확정';
      } else if (result.booking_detail.physician_state === '02') {
        doctorStateVal = '반려';
      } else {
        doctorStateVal = '-';
      }
      if (result.booking_detail.schedule_state === '03') {
        scheduleStateVal = '대기';
      } else if (result.booking_detail.schedule_state === '04') {
        scheduleStateVal = '확정';
      } else if (result.booking_detail.schedule_state === '05') {
        scheduleStateVal = '완료';
      } else if (result.booking_detail.schedule_state === '08') {
        scheduleStateVal = '반려';
      } else if (result.booking_detail.schedule_state === '99') {
        scheduleStateVal = '미응답';
      } else if (result.booking_detail.schedule_state === '999') {
        scheduleStateVal = '노쇼';
      } else {
        scheduleStateVal = '-';
      }
      state.appointInfo = {
        patient: {
          name: result.booking_detail.patient_name,
          patientEmail: result.booking_detail.patient_email,
          managerEmail: result.booking_detail.patient_manager_email,
          nationality: result.booking_detail.patient_nationality,
          state: patientStateVal,
        },
        doctor: {
          name: result.booking_detail.physician_name,
          doctorEmail: result.booking_detail.physician_email,
          managerEmail: result.booking_detail.physician_manager_email,
          nationality: result.booking_detail.physician_nationality,
          affiliation: result.booking_detail.affiliation,
          state: doctorStateVal,
        },
        detail: {
          title: result.booking_detail.patient_title,
          content: result.booking_detail.patient_contents,
          images: result.patient_image_list,
        },
        schedule: {
          date: result.schedule_date,
          time: result.schedule_time,
          endTime: result.booking_detail.booking_end_date,
          state: scheduleStateVal,
          memo: result.physician_public_memo,
          reject: result.physician_reject_desc,
        },
      };
    },
    setAppointInfoError(state, err) {
      return setException(state.exception.appointInfo, false, `bang`, `상담 상세 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
  },
  actions: {
    async getAppointList(context, param) {
      const config = await {
        params: {
          page_index: param.page_index,
          page_rows: param.page_rows,
          search_period: param.search_period,
          search_start_date: param.search_date_begin,
          search_end_date: param.search_date_end,
          search_text: param.search_text,
          search_type: param.search_type,
          patient_action: param.patient_action,
          physician_state: param.physician_state,
          state: param.state,
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/booking/manager/list', config)
        .then((res) => {
          console.log(res);
          context.commit('setAppointList', res.data);
        })
        .catch((err) => {
          console.log(err);
          context.commit('setAppointListError', err.response.data);
        });
    },
    async getAppointInfo(context, booking_sid) {
      await $http
        .get(process.env.VUE_APP_API_HOST + `/api/admin/booking/manager/${booking_sid}`)
        .then((res) => {
          // console.log(res);
          context.commit('setAppointInfo', res.data);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setAppointInfoError', err.response.data);
        });
    },
  },
};
