import { Module } from 'vuex';
import { $http, RootState } from '../index';
const setResult = (st: any, bl: boolean, sc: boolean, tl: string, ds: string): void => {
  st.result.isSuccess = sc;
  st.result.title = tl;
  st.result.desc = ds;
  setTimeout(() => {
    st.result.bool = bl;
  }, 300);
};
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface Result {
  bool: boolean;
  isSuccess: null | boolean;
  title: string;
  desc: string;
}
interface ExceptionItem {
  bool: boolean;
  type: string;
  msg: string;
}
interface Exception {
  boardList: ExceptionItem;
  currentItem: ExceptionItem;
}
export interface BoardItem {
  itemSid: number;
  title: string;
  content?: string;
  createdDate: string;
  writer?: string;
  isChecked: boolean;
}
export interface CsState {
  result: Result;
  exception: Exception;
  currentItem: null | BoardItem;
  boardList: null | Array<BoardItem>;
  totalCount: null | number;
  rowNumber: null | number;
  lastPageNumber: null | number;
}
export const moduleCs: Module<CsState, RootState> = {
  namespaced: true,
  state: {
    result: {
      bool: false,
      isSuccess: null,
      title: '',
      desc: '',
    },
    exception: {
      boardList: {
        bool: false,
        type: '',
        msg: '',
      },
      currentItem: {
        bool: false,
        type: '',
        msg: '',
      },
    },
    currentItem: null,
    boardList: null,
    totalCount: null,
    rowNumber: null,
    lastPageNumber: null,
  },
  getters: {
    getCheckedItems(state) {
      return state.boardList?.filter((board) => board.isChecked === true);
    },
    getLastPageNumber(state) {
      if (state.totalCount !== null) {
        if (state.totalCount % 8 === 0) {
          return Math.floor(state.totalCount / 8);
        } else {
          return Math.floor(state.totalCount / 8) + 1;
        }
      }
    },
  },
  mutations: {
    async setBoardList(state, result) {
      // console.log('------list total count');
      // console.log(result.total_count);
      state.boardList = await [];
      for (let i = 0; i < result.list_board.length; i++) {
        state.boardList.push({
          itemSid: result.list_board[i].board_sid,
          title: result.list_board[i].title,
          createdDate: result.list_board[i].create_date.substr(0, 10),
          writer: '관리자',
          isChecked: false,
        });
      }
      state.totalCount = result.total_count;
      if (result.list_board.length < 1) {
        return setException(state.exception.boardList, true, `empty`, `게시판 목록 조회`, `조회 가능한 게시글이 없습니다.`);
      } else {
        return setException(state.exception.boardList, false, `success`, `게시판 목록 조회`, `${result.total_count}개 조회 성공.`);
      }
    },
    async setBoardListError(state, err) {
      return setException(state.exception.boardList, true, `bang`, `게시판 목록 조회`, `ERROR ${err.code}: ${err.message}`);
    },
    async postBoardItem(state, board) {
      // console.log(board);
      state.currentItem = {
        itemSid: board.board_sid,
        title: board.title,
        createdDate: board.update_date,
        writer: '관리자',
        isChecked: false,
      };
      return setResult(state, true, true, `게시글 등록`, `'${board.title}' 등록 완료`);
    },
    async postBoardItemError(state, err) {
      return setResult(state, true, false, `게시글 삭제`, `삭제 실패. ERROR ${err.code}: ${err.message}`);
    },
    async modiifyBoardItem(state, title) {
      return setResult(state, true, true, `게시글 수정`, `'${title}' 수정 완료`);
    },
    async modifyBoardItemError(state, err) {
      return setResult(state, true, false, `게시글 수정`, `삭제 실패. ERROR ${err.code}: ${err.message}`);
    },
    /**
     * 04. 게시판 글 삭제 m
     */
    async deleteBoardItem(state, title) {
      return setResult(state, true, true, `공지사항 삭제`, `'${title}' 삭제 성공`);
    },
    async deleteBoardItemError(state, err) {
      return setResult(state, true, false, `공지사항 삭제`, `삭제 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setBoardItem(state, board) {
      // console.log(board);
      state.currentItem = {
        itemSid: board.board_sid,
        title: board.title,
        content: board.contents,
        createdDate: board.create_date.substr(0, 10),
        writer: '관리자',
        isChecked: false,
      };
    },
    async setBoardItemError(state, err) {
      // console.log(err);
      state.exception.currentItem.bool = false;
      state.exception.currentItem.type = 'bang';
      state.exception.currentItem.msg = `ERROR ${err.code}: ${err.message}`;
    },
    async check_all(state) {
      if (state.boardList !== null) {
        for (let i = 0; i < state.boardList.length; i++) {
          state.boardList[i].isChecked = true;
        }
      }
    },
    async uncheck_all(state) {
      if (state.boardList !== null) {
        for (let i = 0; i < state.boardList.length; i++) {
          state.boardList[i].isChecked = false;
        }
      }
    },
    async check_item(state, index) {
      if (state.boardList !== null) {
        state.boardList[index].isChecked = true;
      }
    },
    async uncheck_item(state, index) {
      if (state.boardList !== null) {
        state.boardList[index].isChecked = false;
      }
    },
    async reset_result(state) {
      (state.result.bool = false), (state.result.isSuccess = null), (state.result.title = ''), (state.result.desc = '');
    },
  },
  actions: {
    async getBoardList(context, board) {
      // console.log(board);
      const params = await {
        board_category_code: board.board_category_code,
        board_type_code: board.board_type_code,
        page: board.page,
        rows: board.rows,
        search_text: board.search_text,
      };
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/board/list_board.seize', params)
        .then((res) => {
          // console.log(res);
          context.commit('setBoardList', res.data);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setBoardListError', err);
        });
    },
    async postBoardItem(context, board) {
      console.log(board);
      const formData = new FormData();
      (
        await (() => {
          formData.append('board_category_code', board.boardCategory);
          formData.append('board_email', board.email);
          formData.append('board_phone', board.phone);
          formData.append('board_type_code', board.boardType);
          formData.append('board_writer_type', board.writerType);
          formData.append('contents', board.content);
          formData.append('extra1', board.extra1);
          formData.append('extra2', board.extra2);
          formData.append('extra3', board.extra3);
          formData.append('extra4', board.extra4);
          formData.append('title', board.title);
        })
      )();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/board/regist_board_with_attach.seize', formData)
        .then((res) => {
          // console.log(res);
          context.commit('postBoardItem', res.data.board);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('postBoardItemError', err);
        });
    },
    async modifyBoardItem(context, board) {
      // console.log(board);
      const params = await {
        board_sid: board.itemSid,
        board_email: board.email,
        board_phone: board.phone,
        contents: board.content,
        extra1: board.extra1,
        extra2: board.extra2,
        extra3: board.extra3,
        extra4: board.extra4,
        title: board.title,
      };
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/board/modify_board.seize', params)
        .then((res) => {
          // console.log(res);
          context.commit('modifyBoardItem', res.data.board);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('modifyBoardItemError', err);
        });
    },
    async deleteBoardItem(context, checkedItems) {
      const itemSids = [];
      for (let i = 0; i < checkedItems.length; i++) {
        itemSids.push(checkedItems[i].itemSid);
      }
      // console.log(itemSids);
      const params = await {
        board_sids: itemSids,
      };
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/board/delete_board.seize', params)
        .then((res) => {
          // console.log(res);
          context.commit('deleteBoardItem', checkedItems[0].title);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('deleteBoardItemError', err);
        });
    },
    async getBoardItem(context, itemSid) {
      // console.log(itemSid);
      const params = await {
        board_sid: itemSid,
      };
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/board/load_board.seize', params)
        .then((res) => {
          // console.log(res);
          context.commit('setBoardItem', res.data.board);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setBoarItemError', err);
        });
    },
  },
};
