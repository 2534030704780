import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

/** 뷰 */
import HomeVue from '@/views/HomeView.vue';
import LoginVue from '@/views/LoginView.vue';
import ErrorVue from '@/views/ErrorView.vue';

/** 전체메뉴 */
/* [0] 메인 (대시보드) */
import MainVue from '@/views/main/MainView.vue';
import DashBoardVue from '@/views/main/DashBoard.vue';
/* [1] 의료진 관리  1. 의료진 등록  2. 의료진 현황 */
import DoctorRegistrationVue from '@/views/doctor/DoctorRegist.vue';
import DoctorListVue from '@/views/doctor/DoctorList.vue';
/* [2] 환자 관리  1. 환자 현황  */
import PatientListVue from '@/views/patient/PatientList.vue';
/* [3] 상담 관리  1. 상담 현황 */
import AppointListVue from '@/views/appoint/AppointList.vue';
/* [4] 결제 관리  1. 결제 현황 (2. 정산 현황) */
import PaymentListVue from '@/views/accounts/PaymentList.vue';
// import AccountsConditionVue from '@/views/accounts/AccountsList.vue';
/* [5] 고객센터  1. 의료진 공지사항  2. 의료진 FAQ  3. 환자 공지사항  4. 환자 FAQ (영어/ 한국어/ 러시아어) */
import DoctorNoticeVue from '@/views/cs/DoctorNoticeBoard.vue';
import DoctorFAQVue from '@/views/cs/DoctorFAQBoard.vue';
import PatientNoticeVue from '@/views/cs/PatientNoticeBoard.vue';
import PatientFAQVue from '@/views/cs/PatientFAQBoard.vue';
/* [6] 설정 관리  1. 카테고리 */
import CategoryVue from '@/views/setting/CategorySetting.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeVue,
    children: [
      /**
       * [    전체 메뉴    ]
       *
       * [0] 메인 ================================ */
      {
        path: 'main',
        name: 'Main',
        component: MainVue,
        redirect: { name: 'DashBoard' },
        children: [
          {
            // 1. 대시보드
            path: 'dashboard',
            name: 'DashBoard',
            component: DashBoardVue,
          },
        ],
      },
      /* [1] 의료진 관리 =========================== */
      {
        path: 'doctor',
        name: 'Doctor',
        component: DoctorRegistrationVue,
        redirect: { name: 'DoctorRegistration' },
        children: [
          {
            // 1. 의료진 등록
            path: 'regist',
            name: 'DoctorRegistration',
            component: DoctorRegistrationVue,
          },
          {
            // 2. 의료진 현황
            path: 'list',
            name: 'DoctorList',
            component: DoctorListVue,
          },
        ],
      },
      /* [2] 환자 관리 =========================== */
      {
        path: 'patient',
        name: 'Patient',
        component: PatientListVue,
        redirect: { name: 'PatientList' },
        children: [
          {
            // 1. 환자 현황
            path: 'list',
            name: 'PatientList',
            component: PatientListVue,
          },
        ],
      },
      /* [3] 상담 관리 =========================== */
      {
        path: 'appoint',
        name: 'Appoint',
        component: AppointListVue,
        redirect: { name: 'AppointList' },
        children: [
          {
            // 1. 상담 현황
            path: 'list',
            name: 'AppointList',
            component: AppointListVue,
          },
        ],
      },
      /* [4] 결제 관리 =========================== */
      {
        path: 'cost',
        name: 'Cost',
        component: PaymentListVue,
        redirect: { name: 'PaymentList' },
        children: [
          {
            // 1. 결제 현황
            path: 'pay',
            name: 'PaymentList',
            component: PaymentListVue,
          },
          // {
          //   // 2. 정산 현황
          //   path: 'accounts',
          //   name: 'AccountsCondition',
          //   component: AccountsConditionVue,
          // },
        ],
      },
      /* [5] 고객센터 =========================== */
      {
        path: 'cs',
        name: 'Cs',
        component: DoctorNoticeVue,
        redirect: { name: 'DoctorNotice' },
        children: [
          {
            // 1. 의료진 공지사항
            path: 'doctornotice',
            name: 'DoctorNotice',
            component: DoctorNoticeVue,
          },
          {
            // 2. 의료진 FAQ
            path: 'doctorfaq',
            name: 'DoctorFAQ',
            component: DoctorFAQVue,
          },
          {
            // 3.  환자 공지사항
            path: 'patientnotice',
            name: 'PatientNotice',
            component: PatientNoticeVue,
          },
          {
            // 4. 환자 FAQ
            path: 'patientfaq',
            name: 'PatientFAQ',
            component: PatientFAQVue,
          },
        ],
      },
      /* [6] 설정 관리 =========================== */
      {
        path: 'setting',
        name: 'Setting',
        component: CategoryVue,
        redirect: { name: 'Category' },
        children: [
          {
            // 1. 카테고리
            path: 'category',
            name: 'Category',
            component: CategoryVue,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginVue,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
