import { Module } from 'vuex';
import { $http, RootState } from '../index';
import { AnyObject } from 'chart.js/dist/types/basic';
const setResult = (st: any, bl: boolean, sc: boolean, tl: string, ds: string): void => {
  st.result.isSuccess = sc;
  st.result.title = tl;
  st.result.desc = ds;
  setTimeout(() => {
    st.result.bool = bl;
  }, 300);
};
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface RegistResult {
  default: boolean;
  addKr: boolean;
  addRu: boolean;
}
interface ModifyResult {
  default: boolean;
  addEn: boolean;
  addKr: boolean;
  addRu: boolean;
}
interface Result {
  bool: boolean;
  isSuccess: null | boolean;
  title: string;
  desc: string;
}
interface ExceptionItem {
  bool: boolean;
  type: string;
  title: string;
  desc: string;
}
interface Exception {
  doctorList: ExceptionItem;
  doctorInfo: ExceptionItem;
}
interface DoctorInfo {
  doctorSid: number;
  doctorName: string;
  doctorEmail: string;
  metaverseUrl: string;
  nationality: string;
  speciality: null | Array<any>;
  licenseNumber: string;
  licenseImageUrl: null | string;
  licenseImageName: null | string;
  profileImageUrl: null | string;
  profileImageName: null | string;
  affiliationEn: string;
  activityEn: string;
  educationEn: string;
  affiliationKr: null | string;
  activityKr: null | string;
  educationKr: null | string;
  affiliationRu: null | string;
  activityRu: null | string;
  educationRu: null | string;
}
interface DoctorListItem {
  doctorSid: number;
  createdDate: string;
  acceptedDate: string;
  doctorName: string;
  nationality: string;
  affiliation: string;
  subjects: null | Array<string>;
  statusName: string;
}
interface CategoryItem {
  code: string;
  name: string;
}
interface NationalityItem {
  countryId: string;
  countryName: string;
  ios3: string;
}
export interface DrState {
  registResult: RegistResult;
  modifyResult: ModifyResult;
  result: Result;
  exception: Exception;
  doctorResult: null | AnyObject;
  doctorInfo: null | DoctorInfo;
  doctorList: null | Array<DoctorListItem>;
  totalCount: null | number;
  subjects: null | Array<CategoryItem>;
  diseases_onSubject: null | Array<CategoryItem>;
  diseases_result: null | Array<CategoryItem>;
  subjects_result: null | CategoryItem;
  nationalityList: null | Array<NationalityItem>;
  nationality_result: null | NationalityItem;
}
export const moduleDr: Module<DrState, RootState> = {
  namespaced: true,
  state: {
    registResult: {
      default: false,
      addKr: false,
      addRu: false,
    },
    modifyResult: {
      default: false,
      addEn: false,
      addKr: false,
      addRu: false,
    },
    result: {
      bool: false,
      isSuccess: null,
      title: '',
      desc: '',
    },
    exception: {
      doctorList: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
      doctorInfo: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
    },
    doctorResult: null,
    doctorInfo: {
      doctorSid: 0,
      doctorName: '',
      doctorEmail: '',
      metaverseUrl: '',
      nationality: '',
      speciality: [],
      licenseNumber: '',
      licenseImageUrl: '',
      licenseImageName: '',
      profileImageUrl: '',
      profileImageName: '',
      affiliationEn: '',
      activityEn: '',
      educationEn: '',
      affiliationKr: '',
      activityKr: '',
      educationKr: '',
      affiliationRu: '',
      activityRu: '',
      educationRu: '',
    },
    doctorList: null,
    totalCount: null,
    subjects: null,
    diseases_onSubject: null,
    diseases_result: null,
    subjects_result: null,
    nationalityList: null,
    nationality_result: null,
  },
  getters: {
    getNationalityList_name(state) {
      const names = [];
      if (state.nationalityList !== null) {
        for (let i = 0; i < state.nationalityList.length; i++) {
          names.push(state.nationalityList[i].countryName);
        }
      }
      return names;
    },
    getLastPageNumber(state) {
      if (state.totalCount !== null) {
        if (state.totalCount % 8 === 0) {
          return Math.floor(state.totalCount / 8);
        } else {
          return Math.floor(state.totalCount / 8) + 1;
        }
      }
    },
  },
  mutations: {
    async approveDoctorApply(state, physician) {
      return setResult(state, true, true, `의료진 승인`, `${physician.physician_name}(${physician.physician_email}) 승인 성공`);
    },
    async approveDoctorApplyError(state, err) {
      return setResult(state, true, false, `의료진 승인`, `승인 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setDoctorList(state, result) {
      // console.log('------list total count');
      console.log(result);
      state.doctorList = [];
      state.totalCount = result.totalCount;
      for (let i = 0; i < result.physician.length; i++) {
        let statusVal = '대기';
        if (result.physician[i].apply_yn === 'Y') {
          statusVal = '승인';
        } else if (result.physician[i].apply_yn === 'N') {
          statusVal = '대기';
        }
        let subjectsVal = null;
        if (
          result.physician[i].speciality_list !== null &&
          result.physician[i].speciality_list !== 'null' &&
          result.physician[i].speciality_list !== undefined &&
          result.physician[i].speciality_list.length !== 0
        ) {
          subjectsVal = [];
          for (let j = 0; j < result.physician[i].speciality_list.length; j++) {
            subjectsVal.push(result.physician[i].speciality_list[j].subject.name);
          }
        }
        state.doctorList.push({
          doctorSid: result.physician[i].physician_sid,
          createdDate: result.physician[i].create_date.substr(0, 10),
          acceptedDate: result.physician[i].apply_date,
          doctorName: result.physician[i].physician_name,
          nationality: result.physician[i].nationality,
          affiliation: result.physician[i].affiliation,
          subjects: subjectsVal,
          statusName: statusVal,
        });
      }
      if (result.physician.length < 1) {
        return setException(state.exception.doctorList, true, `empty`, `의료진 목록 조회`, `조회 가능한 의료진 목록이 없습니다.`);
      } else {
        return setException(state.exception.doctorList, false, `success`, `의료진 목록 조회`, `${result.totalCount}개 조회 성공.`);
      }
    },
    async setDoctorListError(state, err) {
      return setException(state.exception.doctorList, true, `bang`, `의료진 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setDoctorInfo(state, physician) {
      console.log(physician);
      state.doctorInfo = null;
      // console.log(physician);
      let affiliationKrVal = null;
      let activityKrVal = null;
      let educationKrVal = null;
      let affiliationRuVal = null;
      let activityRuVal = null;
      let educationRuVal = null;
      let metaverseVal = 'https://';
      const metaNones = ['', null, 'null', undefined];
      if (metaNones.indexOf(physician.metaverse_url) === -1) {
        metaverseVal = physician.metaverse_url;
      }
      if (physician.global_info_list !== null && physician.global_info_list.length > 0) {
        for (let i = 0; i < physician.global_info_list.length; i++) {
          if (physician.global_info_list[i].lang_type == 'kr') {
            if (physician.global_info_list[i].affiliation === null || physician.global_info_list[i].affiliation === 'null') {
              affiliationKrVal = '';
            } else if (physician.global_info_list[i].achievement_activity === null || physician.global_info_list[i].achievement_activity === 'null') {
              activityKrVal = '';
            } else if (physician.global_info_list[i].education === null || physician.global_info_list[i].education === 'null') {
              educationKrVal = '';
            } else {
              affiliationKrVal = physician.global_info_list[i].affiliation;
              activityKrVal = physician.global_info_list[i].achievement_activity;
              educationKrVal = physician.global_info_list[i].education;
            }
          } else if (physician.global_info_list[i].lang_type == 'ru') {
            if (physician.global_info_list[i].affiliation === null || physician.global_info_list[i].affiliation === 'null') {
              affiliationRuVal = '';
            } else if (physician.global_info_list[i].achievement_activity === null || physician.global_info_list[i].achievement_activity === 'null') {
              activityRuVal = '';
            } else if (physician.global_info_list[i].education === null || physician.global_info_list[i].education === 'null') {
              educationRuVal = '';
            } else {
              affiliationRuVal = physician.global_info_list[i].affiliation;
              activityRuVal = physician.global_info_list[i].achievement_activity;
              educationRuVal = physician.global_info_list[i].education;
            }
          }
        }
      }
      let licenseImageUrlVal = null;
      let licenseImageNameVal = null;
      let profileImageUrlVal = null;
      let profileImageNameVal = null;
      // 의사면허, 프로필 이미지 URL 조회
      const getImageUrl = async (sid: number, type: string) => {
        const queryStr = `/api/admin/physician/manager/image/${sid}/${type}`;
        await $http
          .get(process.env.VUE_APP_API_HOST + queryStr)
          .then((res) => {
            console.log(res.data.image_datas);
            if (type === `00`) {
              if (res.data.image_datas[0].file_name === undefined || res.data.image_datas[0].file_name === null || res.data.image_datas[0].file_name === '') {
                licenseImageNameVal = '(알 수 없는 파일명)';
              } else {
                licenseImageNameVal = res.data.image_datas[0].file_name;
              }
              licenseImageUrlVal = res.data.image_datas[0].image_url;
            } else if (type == `01`) {
              if (res.data.image_datas[0].file_name === undefined || res.data.image_datas[0].file_name === null || res.data.image_datas[0].file_name === '') {
                profileImageNameVal = '(알 수 없는 파일명)';
              } else {
                profileImageNameVal = res.data.image_datas[0].file_name;
              }
              profileImageUrlVal = res.data.image_datas[0].image_url;
            }
          })
          .catch((err) => {
            // console.log(err);
            licenseImageUrlVal = null;
            licenseImageNameVal = null;
            profileImageUrlVal = null;
            profileImageNameVal = null;
          });
      };
      await getImageUrl(physician.physician_sid, '00');
      await getImageUrl(physician.physician_sid, '01');
      let specialityVal = null;
      if (physician.speciality_list !== null && physician.speciality_list !== 'null' && physician.speciality_list !== undefined && physician.speciality_list.length !== 0) {
        specialityVal = physician.speciality_list;
      }
      if (licenseImageUrlVal !== null) {
        state.doctorInfo = {
          doctorSid: physician.physician_sid,
          doctorName: physician.physician_name,
          doctorEmail: physician.physician_id,
          metaverseUrl: metaverseVal,
          speciality: specialityVal,
          nationality: physician.nationality,
          licenseNumber: physician.license_number,
          licenseImageUrl: licenseImageUrlVal,
          licenseImageName: licenseImageNameVal,
          profileImageUrl: profileImageUrlVal,
          profileImageName: profileImageNameVal,
          affiliationEn: physician.affiliation,
          activityEn: physician.achievement_activity,
          educationEn: physician.education,
          affiliationKr: affiliationKrVal, // 다국어 리스트에서 추출
          activityKr: activityKrVal,
          educationKr: educationKrVal,
          affiliationRu: affiliationRuVal,
          activityRu: activityRuVal,
          educationRu: educationRuVal,
        };
      }
      return setException(state.exception.doctorInfo, false, `success`, `의료진 상세 조회`, `성공`);
    },
    setDoctorInfoError(state, err) {
      // console.log(err);
      return setException(state.exception.doctorInfo, true, `bang`, `의료진 상세 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async modifyDoctorInfo_default(state, physician) {
      state.registResult.default = true;
      return setResult(state, true, true, `의료진 정보 수정`, `${physician.physician_name} 수정 완료`);
    },
    async modifyDoctorInfo_defaultError(state, err) {
      state.modifyResult.default = false;
      return setResult(state, true, false, `의료진 정보 수정`, `기본 정보 수정 실패. ERROR ${err.code}: ${err.message}`);
    },
    async registDoctorInfo_default(state, physician) {
      // console.log(physician);
      state.doctorResult = physician;
      return (state.registResult.default = true);
      // return setResult(state, true, true, `의료진 등록`, `${physician.physician_name} (자동 승인) 등록 성공`);
    },
    async registDoctorInfo_defaultError(state, err) {
      state.registResult.default = false;
      return setResult(state, true, false, `의료진 등록`, `등록 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setNationalityList(state, list) {
      state.nationalityList = [];
      for (let i = 0; i < list.length; i++) {
        state.nationalityList.push({
          countryId: list[i].country_id,
          countryName: list[i].country_name,
          ios3: list[i].ios3,
        });
      }
    },
    async registDoctorInfo_add(state, global_info) {
      if (state.modifyResult.default) {
        console.log(global_info);
        let lang = `알 수 없는 언어`;
        if (global_info.lang_type === 'en') {
          lang = `영어`;
        } else if (global_info.lang_type === 'kr' || global_info.lang_type === 'ko') {
          lang = `한국어`;
        } else if (global_info.lang_type === 'ru') {
          lang = `러시아어`;
        }
        return setResult(state, true, true, `의료진 추가정보 등록`, `${lang} 정보 등록 성공`);
      }
    },
    async registDoctorInfo_addError(state, err) {
      setTimeout(() => {
        return setResult(state, true, false, `의료진 추가정보 등록`, `등록 실패. ERROR ${err.code}: ${err.message}`);
      }, 100);
    },
    async setNationalityListError(state, err) {
      // console.log(err);
      return setResult(state, true, false, `국가 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async searchNationality(state, list) {
      state.nationality_result = {
        countryId: list[0].country_id,
        countryName: list[0].country_name,
        ios3: list[0].ios3,
      };
    },
    async searchNationalityError(state, err) {
      // console.log(err);
    },
    async setDiseases_onSubject(state, list) {
      state.diseases_onSubject = [];
      for (let i = 0; i < list.length; i++) {
        state.diseases_onSubject.push({
          code: list[i].code,
          name: list[i].name,
        });
      }
    },
    async setDiseases_onSubjectError(state, err) {
      // console.log(err);
    },
    async setSubjects(state, list) {
      state.subjects = [];
      for (let i = 0; i < list.length; i++) {
        state.subjects.push({
          code: list[i].code,
          name: list[i].name,
        });
      }
    },
    async resetDiseases_onSubject(state) {
      state.diseases_onSubject = [];
    },
    async resetSubjects(state) {
      state.subjects = [];
    },
    async reset_result(state) {
      (state.result.bool = false), (state.result.isSuccess = null), (state.result.desc = '');
    },
  },
  actions: {
    async approveDoctorApply(context, doctorSid) {
      // console.log(doctorSid);
      const param = {
        physician_sid: doctorSid,
      };
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/apply', param)
        .then((res) => {
          // console.log(res);
          context.commit('approveDoctorApply', res.data.physician);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('approveDoctorApplyError', err);
        });
    },
    async getDoctorList(context, param) {
      // console.log(`----------------의료진 목록 params`);
      // console.log(param);
      const config = {
        params: {
          page: param.page,
          rows: param.rows,
          search_period: param.search_period,
          search_date_begin: param.search_date_begin,
          search_date_end: param.search_date_end,
          search_text: param.search_text,
          search_type: param.search_type,
          apply_state: param.apply_state,
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/list', config)
        .then((res) => {
          // console.log(res);
          context.commit('setDoctorList', res.data);
        })
        .catch((err) => {
          console.log(err);
          context.commit('setDoctorListError', err.response.data);
        });
    },
    async getDoctorInfo(context, doctorSid) {
      const config = {
        params: {
          physician_sid: doctorSid, // *
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/load', config)
        .then((res) => {
          // console.log(res);
          context.commit('setDoctorInfo', res.data.physician);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setDoctorInfoError', err);
        });
    },
    async modifyDoctorInfo_default(context, doctor) {
      console.log(doctor);
      let meta = '';
      if (doctor.metaverseUrl !== 'https://') {
        meta = doctor.metaverseUrl;
      } else {
        meta = '';
      }
      const formData = new FormData();
      if (doctor.licenseImage !== null) {
        formData.append('license_image', doctor.licenseImage);
      }
      if (doctor.profileImage !== null) {
        formData.append('profile_image', doctor.profileImage);
      }
      formData.append('license_number', doctor.licenseNumber);
      formData.append('nationality', doctor.nationality);
      formData.append('physician_sid', doctor.doctorSid);
      const metaNones = ['https://', '', null, undefined];
      if (metaNones.indexOf(doctor.metaverseUrl) === -1) {
        formData.append('metaverse_url', doctor.metaverseUrl);
      }
      if (doctor.diseases.length !== 0 && doctor.diseases !== null && doctor.diseases !== undefined) {
        formData.append('subject_disease_list', '');
        for (let i = 0; i < doctor.diseases.length; i++) {
          const dto = JSON.stringify(doctor.diseases[i]);
          formData.append('subject_disease_list', dto);
        }
      }
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/modify', formData)
        .then((res) => {
          console.log(res);
          context.commit('modifyDoctorInfo_default', res.data.physician);
        })
        .catch((err) => {
          console.log(err);
          context.commit('modifyDoctorInfo_defaultError', err);
        });
    },
    async registDoctorInfo_add(context, add) {
      console.log(add);
      const objParam = {
        physician_sid: add.physician_sid,
        achievement_activity: add.activity,
        affiliation: add.affiliation,
        education: add.education,
        lang: add.lang, // 언어 (kr,en,ru)
      };
      const queryParam = new URLSearchParams(objParam).toString();
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/modifyInfo', queryParam)
        .then((res) => {
          console.log(res);
          context.commit('registDoctorInfo_add', res.data.global_info);
        })
        .catch((err) => {
          console.log(err);
          context.commit('registDoctorInfo_addError', err);
        });
    },
    async registDoctorInfo_default(context, doctor) {
      // console.log(doctor);
      const formData = new FormData();
      formData.append('pw', doctor.password);
      formData.append('achievement_activity', doctor.activityEn);
      formData.append('affiliation', doctor.affiliationEn);
      formData.append('education', doctor.educationEn);
      formData.append('email', doctor.doctorEmail);
      formData.append('license_image', doctor.licenseImage);
      formData.append('license_number', doctor.licenseNumber);
      const metaNones = ['https://', '', null, undefined];
      if (metaNones.indexOf(doctor.metaverseUrl) === -1) {
        formData.append('metaverse_url', doctor.metaverseUrl);
      }
      formData.append('name', doctor.doctorName);
      formData.append('nationality', doctor.nationality);
      formData.append('profile_image', doctor.profileImage);
      if (doctor.diseases.length !== 0 && doctor.diseases !== null && doctor.diseases !== undefined) {
        formData.append('subject_disease_list', '');
        for (let i = 0; i < doctor.diseases.length; i++) {
          const dto = JSON.stringify(doctor.diseases[i]);
          formData.append('subject_disease_list', dto);
        }
      }
      await $http
        .post(process.env.VUE_APP_API_HOST + '/api/admin/physician/manager/regist', formData)
        .then((res) => {
          console.log(res);
          context.commit('registDoctorInfo_default', res.data.physician);
        })
        .catch((err) => {
          console.log(err);
          context.commit('registDoctorInfo_defaultError', err);
        });
    },
    async getNationalityList(context, word) {
      const config = {
        params: {
          search_country: word,
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/common/country/all', config)
        .then((res) => {
          // console.log(res);
          context.commit('setNationalityList', res.data.list);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setNationalityListError', err);
        });
    },
    async searchNationality(context, word) {
      const config = {
        params: {
          search_country: word,
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/common/country/all', config)
        .then((res) => {
          // console.log(res);
          context.commit('searchNationality', res.data.list);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('searchNationalityError', err);
        });
    },
    async getDiseases_onSubject(context, subjectCode) {
      const config = {
        params: {
          lang: 'ko',
          subject: subjectCode,
        },
        headers: {
          auth_session: localStorage.getItem('authToken'),
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/common/medical/disease', config)
        .then((res) => {
          // console.log(res);
          context.commit('setDiseases_onSubject', res.data.list);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setDiseases_onSubjectError', err);
        });
    },
    async getSubjects(context) {
      const config = {
        params: {
          lang: `ko`,
        },
        headers: {
          auth_session: localStorage.getItem('authToken'),
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/common/medical/subject', config)
        .then((res) => {
          context.commit('setSubjects', res.data.list);
        })
        .catch((err) => {
          context.commit('setSubjectsError', err);
        });
    },
  },
};
