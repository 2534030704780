import { createStore } from 'vuex';
import { moduleAuth, AuthState } from '@/store/modules/auth';
import { moduleMain, MainState } from '@/store/modules/main';
import { moduleMenu, MenuState } from '@/store/modules/menu';
import { moduleDr, DrState } from '@/store/modules/doctor';
import { modulePt, PtState } from '@/store/modules/patient';
import { moduleAppt, ApptState } from '@/store/modules/appoint';
import { moduleAcct, AcctState } from '@/store/modules/accounts';
import { moduleCs, CsState } from '@/store/modules/cs';
import { moduleSet, SetState } from '@/store/modules/setting';
import axios from 'axios';
import router from '@/router';

export const $http = axios.create();

$http.defaults.withCredentials = true;

$http.defaults.headers.common['session'] = localStorage.getItem('authToken');
$http.defaults.headers.common['time_zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
$http.defaults.headers.common['service_lang'] = 'ko';

$http.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.session = `${token}`;
  }
  return config;
});

export interface RootState {
  ModuleAuth: AuthState;
  ModuleMain: MainState;
  ModuleMenu: MenuState;
  ModuleDr: DrState;
  ModulePt: PtState;
  ModuleAppt: ApptState;
  ModuleAcct: AcctState;
  ModuleCs: CsState;
  ModuleSet: SetState;
}
const store = {
  modules: {
    moduleAuth,
    moduleMain,
    moduleMenu,
    moduleDr,
    modulePt,
    moduleAppt,
    moduleAcct,
    moduleCs,
    moduleSet,
  },
};

export default createStore(store);

$http.interceptors.response.use(
  (res) => {
    // console.log(response);
    return res;
  },
  async (err) => {
    const errConfig = err.config;
    if (err.response.status === 401) {
      errConfig.retry = true;
      localStorage.removeItem('authToken');
      localStorage.removeItem('isValidToken');
      router.replace({ name: 'Login' });
      await $http(errConfig);
    }
    return Promise.reject(err);
  }
);
