import { Module } from 'vuex';
import { $http, RootState } from '../index';
const setResult = (st: any, bl: boolean, sc: boolean, tl: string, ds: string): void => {
  st.result.isSuccess = sc;
  st.result.title = tl;
  st.result.desc = ds;
  setTimeout(() => {
    st.result.bool = bl;
  }, 300);
};
const setException = (tg: any, bl: boolean, tp: string, tl: string, ds: string): void => {
  tg.type = tp; // 'bang', 'empty', 'success',
  tg.title = tl;
  tg.desc = ds;
  setTimeout(() => {
    tg.bool = bl;
  }, 300);
};
interface ExceptionItem {
  bool: boolean;
  type: string;
  title: string;
  desc: string;
}
interface Exception {
  patientList: ExceptionItem;
  patientInfo: ExceptionItem;
}
interface PatientListItem {
  patientSid: number;
  createdDate: string;
  patientName: string;
  nationality: string;
  idEmail: string;
  diseasesOnSubjects: null | Array<string>;
}
interface PatientInfo {
  patientName: string;
  patientId: string;
  nationality: string;
  interestingDiseases: null | Array<string>;
}
export interface PtState {
  exception: Exception;
  patientList: null | Array<PatientListItem>;
  totalCount: null | number;
  patientInfo: null | PatientInfo;
}
export const modulePt: Module<PtState, RootState> = {
  namespaced: true,
  state: {
    exception: {
      patientList: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
      patientInfo: {
        bool: false,
        type: '',
        title: '',
        desc: '',
      },
    },
    patientList: null,
    totalCount: null,
    patientInfo: {
      patientName: ``,
      patientId: ``,
      nationality: ``,
      interestingDiseases: [],
    },
  },
  getters: {
    getLastPageNumber(state) {
      if (state.totalCount !== null) {
        if (state.totalCount % 8 === 0) {
          return Math.floor(state.totalCount / 8);
        } else {
          return Math.floor(state.totalCount / 8) + 1;
        }
      }
    },
  },
  mutations: {
    async setPatientList(state, result) {
      console.log(result);
      state.patientList = await [];
      state.totalCount = result.total_count;
      for (let i = 0; i < result.list_member.length; i++) {
        let diseasesVal = null;
        if (result.list_member[i].interesting_subject_disease_list !== null) {
          diseasesVal = [];
          for (let j = 0; j < result.list_member[i].interesting_subject_disease_list.length; j++) {
            for (let k = 0; k < result.list_member[i].interesting_subject_disease_list[j].disease_list.length; k++) {
              diseasesVal.push(result.list_member[i].interesting_subject_disease_list[j].disease_list[k].name);
            }
          }
        } else {
          diseasesVal = null;
        }
        state.patientList.push({
          patientSid: result.list_member[i].patient_sid,
          createdDate: result.list_member[i].create_date.substr(0, 10),
          patientName: result.list_member[i].patient_name,
          nationality: result.list_member[i].nationality,
          idEmail: result.list_member[i].patient_email,
          diseasesOnSubjects: diseasesVal,
        });
      }
      if (result.list_member.length < 1) {
        return setException(state.exception.patientList, true, `empty`, `환자 목록 조회`, `조회 가능한 환자 목록이 없습니다.`);
      } else {
        return setException(state.exception.patientList, false, `success`, `환자 목록 조회`, `${result.total_count}개 조회 성공.`);
      }
    },
    setPatientListError(state, err) {
      return setException(state.exception.patientList, true, `bang`, `환자 목록 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
    async setPatientInfo(state, result) {
      // console.log(result);
      let diseasesVal = null;
      if (result.interesting_subject_disease_list !== null) {
        diseasesVal = [];
        for (let i = 0; i < result.interesting_subject_disease_list.length; i++) {
          for (let j = 0; j < result.interesting_subject_disease_list[i].disease_list.length; j++) {
            diseasesVal.push(result.interesting_subject_disease_list[i].disease_list[j].name);
          }
        }
      } else {
        diseasesVal = null;
      }
      state.patientInfo = {
        patientName: result.patient_name,
        patientId: result.patient_email,
        nationality: result.nationality,
        interestingDiseases: diseasesVal,
      };
    },
    setPatientInfoError(state, err) {
      return setException(state.exception.patientInfo, true, `bang`, `환자 상세 조회`, `조회 실패. ERROR ${err.code}: ${err.message}`);
    },
  },
  actions: {
    async getPatientList(context, param) {
      // console.log(param);
      const config = await {
        params: {
          page: param.page, // *
          rows: param.rows, // *
          search_date_begin: param.search_date_begin, // * yyyy-MM-dd
          search_date_end: param.search_date_end, // * yyyy-MM-dd
          search_period: param.search_period,
          search_text: param.search_text, // *
          search_type: param.search_type, // * 검색 조건 (0:전체, 1:환자명, 2:이메일, 3:진료질환)
        },
      };
      await $http
        .get(process.env.VUE_APP_API_HOST + '/api/admin/member/manager/list', config)
        .then((res) => {
          // console.log(res);
          context.commit('setPatientList', res.data);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setPatientListError', err.response.data);
        });
    },
    /**
     * 02. 환자 상세 조회 a [ o ]
     */
    async getPatientInfo(context, sid) {
      // console.log(sid);
      await $http
        .get(process.env.VUE_APP_API_HOST + `/api/admin/member/manager/view/${sid}`)
        .then((res) => {
          // console.log(res);
          context.commit('setPatientInfo', res.data.member);
        })
        .catch((err) => {
          // console.log(err);
          context.commit('setPatientInfoError', err);
        });
    },
  },
};
